.home {
  display: flex;
  gap: 43px;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 50px 0;
  width: 100%;
  align-items: center;
  align-content: center;
  .banner-container {
    position: relative;
    width: 100%;
    height: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .banner-title {
    position: absolute;
    z-index: 1;
    color: var(--font-title-color);
    font-size: 48px;
  }
  @media (max-width: 768px) {
    padding: 27px 0;
    gap: 23px;
    .banner-title {
      font-size: 24px;
      padding: 0 23% 0 5%;
    }
  }
}