.error {
  display: flex;
  width: 100%;
  padding: 139px 0;
  flex-direction: column;
  align-items: center;
  gap: 139px;
  color: var(--font-color);
  &-title--h1,
  &-title--h2 {
    margin: 0;
    text-align: center;
  }
  &-title--h1 {
    font-size: 288px;
  }
  &-title--h2 {
    font-size: 36px;
  }
  &-nav-home {
    text-decoration: var(--font-color);
    text-decoration-line: underline;
    color: var(--font-color);
    font-size: 18px;
  }
  @media (max-width: 768px) {
    padding: 113px 0;
    gap: 113px;
    &-title--h1 {
      font-size: 96px;
    }

    &-title--h2 {
      font-size: 18px;
    }

    &-nav-home {
      font-size: 14px;
    }
  }
}