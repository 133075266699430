.gallery {
    display: flex;
    grid-gap: 50px 60px;
    width: 100%;
    max-height: 100%;
    position: relative;
    background:var(--collapse-bg-color);
    border-radius: 25px;
    justify-items: center;
    padding: 43px 0;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    @media (max-width: 768px) {
        padding: 0;
        gap: 20px;
        background: none;
    }
}