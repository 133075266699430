.card-link,
.card-link * {
  box-sizing: border-box;
}
.card-link {
  width: 340px;
  height: 340px;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
    height: 255px;
  }
}
.card {
  border-radius: 10px;
  width: 100%;
  height: 100%;
&-image {
  background: var(--collapse-btn-bg-color);
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
  &-title {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.5));
    color: var(--font-title-color);
    text-align: left;
    z-index: 1;
    font: {
      size: 18px;
      line-height: 142.6%;
    }
    position: absolute;
    padding: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    border-radius: 10px;
    @media (max-width: 768px) {
      padding: 0 0 15px 20px;
    }
  }
}