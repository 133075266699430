.navbar {
  height: 68px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 45px;
  z-index: 1;
  position: relative;

  & .logo {
    width: 210.322px;
    height: 100%;
    flex-shrink: 0;
  }

  &_link {
    display: flex;
    align-items: flex-start;
    gap: 57px;

    & a {
      text-decoration: none;
      color: var(--font-color);
      text-align: right;
      font-size: 24px;
      font-weight: 500;
      line-height: 142.6%;
    }

    a.active-link {
      text-decoration-line: underline;
    }
  }

  // Styles pour les appareils mobiles
  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
    padding-top: 20px;
    & .logo {
      width: 145px;
      flex-shrink: 0;
    }
    &_link {
      justify-content: center;
      gap: 26px;
      flex: 1 0 0;
    }
    & a {
      font-size: 12px;
      text-transform: uppercase;
    }
  }
}