.about-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  gap: 38px;
  padding: 38px 0;
  @media (max-width: 768px) {
    padding: 19px 0;
    gap: 19px;
  }
  .banner-container {
    position: relative;
    width: 100%;
    height: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .about-collapses {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 83%;
    gap: 30px;
    @media (max-width: 768px) {
      gap: 20px;
      width: 100%;
    }
  }
  .collapse-button {
    border-radius: 5px;
  }
}