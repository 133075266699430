@mixin box-btn-color {
  background-color: var(--collapse-btn-bg-color);
  color: var(--font-title-color);
}
.collapse-button {
  border: none;
  height: 52px;
  @include box-btn-color;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  font-size: 24px;
  @media (max-width: 768px) {
    height: 30px;
    font-size: 13px;
  }
  .collapse-icon {
    width: 32px;
    height: 32px;
    transition: transform 300ms ease-out;
    /* Ajout d'une transition pour l'animation */
    &.rotate {
      transform: rotate(-180deg);
      /* Rotation de 180 degrés lorsque le composant est ouvert */
    }
  }
}
.collapse {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px 10px 5px 5px;
  background: var(--collapse-bg-color);
  align-items: flex-start;
  gap: 10px;
}
.collapse-content {
  padding: 0 20px 20px 20px;
  color: var(--font-color);
  font-size: 18px;
  font-weight: 400;
  line-height: 142.6%;
  @media (max-width: 768px) {
      font-size: 12px;
      padding: 0 20px 19px 20px;
    }
}
