.banner {
  width: 100%;
  height: 223px;
  border-radius: 25px;
  background: var(--footer-bg-color);
  &_img {
    width: 100%;
    height: 100%;
    border-radius: 25px;
    object-fit: cover;
    object-position: center;
    filter: brightness(70%);
  }
  @media (max-width: 768px) {
    height: 111px;
  }
}