* {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-style: normal;
}
:root {
  --main-bg-color: #fff;
  --collapse-btn-bg-color: #ff6060;
  --collapse-bg-color: #f6f6f6;
  --footer-bg-color: #000000;
  --font-color: #ff6060;
  --font-title-color: #ffffff;
  --host-bg-color: #c4c4c4;
  --star-rating-true-color: #ff6060;
  --star-rating-false-color:#E3E3E3;
}
body {
  width: 100%;
  height: max-content;
  padding-bottom: 0;
}
main,
header {
  display: flex;
  padding: 0 7%;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  max-width: 1240px;
  margin: auto;
  @media (max-width: 768px) {
    max-width: 335px;
    align-items: center;
  }
}
ul {
  list-style: none;
  padding-left: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}