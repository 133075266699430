.carrousel {
  position: relative;
  width: 100%;
  height: 415px;
  &-image {
    width: 100%;
    height: 100%;
    border-radius: 25px;
    object-fit: cover;
    object-position: center;
  }
  &-navigation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
  }
  &-button:first-child {
    left: 0;
  }
  &-button:last-child {
    right: 0;
  }
  &-counter {
    color: var(--font-title-color);
    font-size: 18px;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
  @media (max-width: 768px) {
    height: 255px;
    &-button > svg {
      width: 24px;
      height: 24px;
    }
  }
}