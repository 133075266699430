.footer {
  background: var(--footer-bg-color);
  color: var(--font-title-color);
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 2% 0 1% 0;
  gap: 28px;
  position: relative;
  bottom: 0;
  &-logo {
    width: 122px;
    height: 39.444px;
    flex-shrink: 0;
  }
  &-title {
    text-align: center;
    font-size: 24px;
  }
  @media (max-width: 768px) {
    padding: 66px 0 58px 0;
    gap: 0;
    &-logo {
      width: 122px;
      height: 39.4px;
    }
    &-title {
      width: 134px;
      font-size: 12px;
    }
  }
}